<template lang="pug">
    Modal(:width="'560px'")
        template(v-slot:body)
            create-feedback-form(@close="$emit('close')")
            
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    components:{
        Modal: () =>
            import("skillbase-component/src/components/common/Modal.vue"),
        CreateFeedbackForm: ()=>
            import("skillbase-component/src/components/feedback/components/CreateFeedbackForm.vue")
    }

})
</script>
