<template lang="pug">
    .user-menu(v-if="$store.getters['search/searchOpened'] == false")
        template(v-if="$route.meta.layout == 'user'")
            .user-menu--modules
                module-row.user-menu--modules--module(
                    :urlIcon="homeIcon",
                    :label="$t('home')",
                    link="/home",
                    :isActive="$route.meta.menu == 'home'"
                )
                module-row.user-menu--modules--module(
                    v-if="modules",
                    v-for="(module, uuid) in showModules",
                    :urlIcon="module.icon",
                    :key="uuid",
                    :link="$route.meta.layout == 'user' ? module.homeRoute : module.adminRoute",
                    :label="$t(module.name)",
                    :isActive="$route.meta.menu == uuid"
                )

                module-row.user-menu--modules--module(
                    v-if="modules",
                    v-for="(module, uuid) in erpModules",
                    :urlIcon="module.icon",
                    :key="uuid",
                    :link="$route.meta.layout == 'user' ? module.homeRoute : module.adminRoute",
                    :label="$t(module.name)",
                    :isActive="$route.query.type == uuid"
                )
            separation-line.hr
            .user-menu--additional
                module-row.user-menu--additional--module(
                    :urlIcon="helpIcon",
                    :label="$t('help')",
                    link="/help",
                    :isActive="$route.meta.menu == 'help'"
            )
                module-row.user-menu--additional--module(
                    :urlIcon="contactIcon",
                    :label="$t('feedback')",
                    :link="feedbackCallback()"
            )
        template(v-if="$route.meta.layout == 'admin'")
            .user-menu--modules
                module-row.user-menu--modules--module(
                    :urlIcon="kpiIcon",
                    :label="$t('admin.kpi.title')",
                    link="/admin/kpi",
                    :isActive="$route.meta.menu == 'admin-kpi'"
                )
                module-row.user-menu--modules--module(
                    :urlIcon="directoryIcon"
                    :label="$t('directory')"
                    link="/admin/users"
                    :isActive="$route.meta.menu == 'admin-users'"
                )
                module-row.user-menu--modules--module(
                    :urlIcon="gestionIcon",
                    :label="$t('manage')",
                    link="/admin/manage",
                    :isActive="$route.meta.menu == 'admin-manage'"
                )
                module-row.user-menu--modules--module(
                    v-if="modules",
                    v-for="(module, uuid) in erpModules",
                    :urlIcon="module.icon",
                    :key="uuid",
                    :link="$route.meta.layout == 'user' ? module.homeRoute : module.adminRoute",
                    :label="$t(module.name)",
                    :isActive="$route.query.type == uuid"
                )
                module-row.user-menu--modules--module(
                    :urlIcon="contactIcon",
                    :label="$t('Feedback')",
                    link="/admin/feedback",
                    :isActive="$route.meta.menu == 'admin-feedback'"
                )
        template(v-if="$route.meta.layout == 'moderator'")
            .user-menu--modules
                module-row.user-menu--modules--module(
                    :urlIcon="homeIcon"
                    :label="$t('home')"
                    link="/moderator"
                    :isActive="$route.meta.menu == 'moderator'"
                )
                module-row.user-menu--modules--module(
                    :urlIcon="groupIcon"
                    :label="$t('groups')"
                    link="/moderator/groups"
                    :isActive="$route.meta.menu == 'moderator-groups'"
                )
                module-row.user-menu--modules--module(
                    :urlIcon="afiIcon"
                    :label="$t('Ask for it')"
                    link="/moderator/requests"
                    :isActive="$route.meta.menu == 'moderator-requests'"
                )
                module-row.user-menu--modules--module(
                    :urlIcon="articleIcon"
                    :label="$t('articles')"
                    link="/moderator/articles"
                    :isActive="$route.meta.menu == 'moderator-articles'"
                )
</template>

<script lang="ts">
import Vue from 'vue';
import ModulesLib from '@/applicationSettings/Modules.js';
import HomeIcon from '@/assets/icon/home-icon.svg';
import GestionIcon from 'skillbase-component/src/components/common/icons/layout/gestion.svg';
import erpIcon from '@/assets/modules/project-icon.svg';
import DirectoryIcon from '@/assets/modules/directory-icon.svg';
import AfiIcon from '@/assets/modules/afi-icon.svg';
import GroupIcon from '@/assets/modules/group-icon.svg';
import ArticleIcon from '@/assets/modules/article-icon.svg';
import KPIIcon from '@/assets/modules/kpi.svg';
import HelpIcon from '@/assets/icon/help.svg';
import ContactIcon from '@/assets/icon/contact.svg';
import CreateFeedback from './../feedback/CreateFeedbackModal.vue';

export default Vue.extend({
    components: {
        ModuleRow: () => import('@/components/pc/UserMenu/ModuleRow'),
        SeparationLine: () => import('skillbase-component/src/components/common/information/SeparationLine.vue'),
    },

    data() {
        return {
            modules: null,
            disconnectAction: {},
            homeIcon: HomeIcon,
            gestionIcon: GestionIcon,
            erpIcon: erpIcon,
            directoryIcon: DirectoryIcon,
            afiIcon: AfiIcon,
            groupIcon: GroupIcon,
            articleIcon: ArticleIcon,
            kpiIcon: KPIIcon,
            helpIcon: HelpIcon,
            contactIcon: ContactIcon
        };
    },

    computed: {
        showModules() {
            const toReturn = {};

            if (this.modules) {
                for (const key of Object.keys(this.modules)) {
                    if (key != 'erp') {
                        toReturn[key] = this.modules[key];
                    }
                }
            }

            return toReturn;
        },

        erpModules() {
            const toReturn = {};
            for (const type of this.erpTypes) {
                if (this.$store.getters['erp/metas'](type)) {
                    toReturn[type] = {
                        icon: this.$store.getters['erp/metas'](type).icon,
                        name: this.$store.getters['erp/metas'](type).label,
                        homeRoute: { path: '/erp/home', query: { type: type } },
                        adminRoute: { path: '/admin/erp', query: { type: type } },
                    };
                }
            }
            return toReturn;
        },

        erpTypes() {
            return this.$store.getters['erp/erpTypes'];
        },
    },

    watch: {
        modules(mods) {
            for (const key of Object.keys(mods)) {
                if (key == 'erp') {
                    this.$store.dispatch('erp/loadAllErpTypes');
                }
            }
        },
    },
    methods: {
        feedbackCallback(){
            return {
                cb: () => {
                    this.$eventBus.$emit(
                        "ModalLauncher:load",
                        CreateFeedback,
                        {}
                    );
                }
            }
        }
    },

    mounted() {
        ModulesLib.getModules().then((modules) => {
            this.modules = modules;
        });

        this.disconnectAction.cb = () => {
            this.$store.dispatch('logout');
            this.$router.push('/login');
        };
    },
});
</script>

<style lang="scss" scoped>
.user-menu {
    background-color: white;
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 5px 5px var(--shadow-color);

    & > div {
        width: 100%;
    }

    &--modules {
        display: flex;
        flex-direction: column;
        height: calc(100% - 158px);
        overflow-y: scroll;
        overflow-x: hidden;
        &--module {
            margin-bottom: 21px;
        }
        &--module:first-child {
            padding-top: 42px;
        }
    }

    &--additional {
        position: absolute;
        bottom: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &--module {
            margin-bottom: 20px;
        }
    }

    .hr {
        position: absolute;
        bottom: 158px;
        width: 100%;
        margin: 0;
    }
}
</style>
